// extracted by mini-css-extract-plugin
export var grid = "index-page-module--grid--3aHyc";
export var gallery = "index-page-module--gallery--3MN4C";
export var img = "index-page-module--img---OYU3";
export var box = "index-page-module--box--rsljj";
export var projectInfo = "index-page-module--projectInfo--3r2_k";
export var category = "index-page-module--category--1e6Dz";
export var loader = "index-page-module--loader--1BXYR";
export var loaderWordmarkWrapper = "index-page-module--loaderWordmarkWrapper--2oYJ_";
export var loaderWordmark = "index-page-module--loaderWordmark--bIasc";
export var loaderGrid = "index-page-module--loaderGrid--1tdka";
export var loaderImage = "index-page-module--loaderImage--2PfaF";